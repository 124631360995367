<template>
  <v-card outlined color="#e8eaf6" class="pa-1 pt-0 mt-1">
    <v-card-text class="pa-1 pt-0 pb-0">
      <v-row>
        <v-col cols="8" class="pa-0 pl-5">
          <v-row class="mt-1">
            <v-col cols="12" class="pa-0">
              <b>{{ $t('message.ccsheet.warehouse') }}:</b> {{ model.warehousename }}
            </v-col>
            <v-col cols="12" class="pa-0">
              {{ $t('message.ccsheet.created') }}: {{ model.createdon | dateFormat }}
            </v-col>
            <v-col cols="12" class="pa-0">
              {{ $t('message.ccsheet.counted') }}: {{ model.counted }}%
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="mt-2 text-center">
          <template v-if="model.status === 'counted'">
            <v-btn small color="primary" dense @click="$router.push(`cycle_count/recount/${model.id}/${!model.recount_status}`)">
                {{ $t('message.ccsheet.counted') }}
              </v-btn>
            </template>
          <template v-else-if="model.status === 'recount'">
            <v-btn small color="primary" dense @click="$router.push(`cycle_count/recount/${model.id}/false`)">
              {{ $t('message.ccsheet.reCount') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn color="primary" dense @click="$router.push(`cycle_count/count/${model.id}`)">
              {{ $t('message.ccsheet.count') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object
    }
  }
}
</script>
